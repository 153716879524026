<template>
	<el-alert
		:title="$t('noCurrentSchoolyearWarning')"
		type="warning"
		class="mb-5"
		v-if="!currentSchoolyear && tableData.length" />

	<el-alert
		:title="$t('noAdminSchoolyearWarning')"
		type="warning"
		class="mb-5"
		v-if="!adminSchoolyear && tableData.length" />

	<el-card shadow="hover">
		<el-row align="middle">
			<el-col :span="12">
				<div class="text-left">
					<h3>{{ $t('schoolyearsList') }}</h3>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="text-right">
					<el-button size="default" @click="addDialog = true">
						{{ $t('add') }}
					</el-button>
				</div>
			</el-col>
		</el-row>

		<el-divider></el-divider>

		<el-table
			:data="pagedTableData"
			:empty-text="$t('noData')"
			border
			style="width: 100%"
			stripe
			v-loading="isLoading">
			<el-table-column type="index" :index="indexMethod"> </el-table-column>
			<el-table-column sortable :label="$t('code')" prop="code">
			</el-table-column>
			<el-table-column sortable :label="$t('startDate')" prop="start_date">
				<template #default="scope">
					{{ reformatDate(scope.row.start_date) }}
				</template>
			</el-table-column>
			<el-table-column sortable :label="$t('endDate')" prop="end_date">
				<template #default="scope">
					{{ reformatDate(scope.row.end_date) }}
				</template>
			</el-table-column>
			<el-table-column :label="$t('status')">
				<template #default="scope">
					<el-tag type="success" v-if="scope.row.id == currentSchoolyear">
						{{ $t('active') }}
					</el-tag>
					<template v-else>
						<el-tag v-if="differenceWithToday(scope.row.end_date) <= 0">
							{{ $t('completed') }}
						</el-tag>
						<el-tag type="warning" v-else>
							{{ $t('upcoming') }}
						</el-tag>
					</template>
				</template>
			</el-table-column>

			<el-table-column
				align="right"
				:width="tableData.length && tableData.length > 1 ? '350' : '200'">
				<template #header>
					<el-input
						v-model="search"
						size="small"
						:placeholder="$t('search')"
						style="width: 175px" />
				</template>

				<template #default="scope">
					<el-button
						size="small"
						type="success"
						@click="importData(scope.$index, scope.row)"
						v-if="
							tableData.length &&
							tableData.length > 1 &&
							typeof scope.row.has_data != VarTypes.UNDEFINED &&
							!scope.row.has_data
						">
						{{ $t('importData') }}
					</el-button>
					<el-button size="small" @click="editItem(scope.$index, scope.row)">
						{{ $t('edit') }}
					</el-button>
					<el-button
						size="small"
						type="danger"
						@click="deleteItem(scope.$index, scope.row)">
						{{ $t('delete') }}
					</el-button>
				</template>
			</el-table-column>
		</el-table>

		<div style="text-align: center; margin-top: 20px">
			<el-pagination
				background
				layout="prev, pager, next"
				@current-change="setPage"
				:hide-on-single-page="true"
				:page-size="pageSize"
				:total="total">
			</el-pagination>
		</div>
	</el-card>

	<el-dialog :title="$t('addSchoolyear')" v-model="addDialog" width="45%">
		<el-form
			:inline="true"
			:model="addSchoolyear"
			:rules="rules"
			:ref="addSchoolyearForm">
			<el-form-item prop="code">
				<el-input :placeholder="$t('code')" v-model="addSchoolyear.code" />
			</el-form-item>

			<el-form-item prop="range">
				<el-config-provider :locale="locale">
					<el-date-picker
						v-model="addSchoolyear.range"
						type="daterange"
						:unlink-panels="true"
						:range-separator="$t('to')"
						:start-placeholder="$t('startDate')"
						:end-placeholder="$t('endDate')" />
				</el-config-provider>
			</el-form-item>
		</el-form>

		<template #footer>
			<span class="dialog-footer">
				<el-button @click="cancelForm(addSchoolyearForm)">{{
					$t('cancel')
				}}</el-button>
				<el-button type="primary" @click="submitForm(addSchoolyearForm)">{{
					$t('confirm')
				}}</el-button>
			</span>
		</template>
	</el-dialog>

	<el-dialog :title="$t('editSchoolyear')" v-model="editDialog" width="45%">
		<el-form
			:inline="true"
			:model="editSchoolyear"
			:rules="rules"
			:ref="editSchoolyearForm">
			<el-form-item prop="code">
				<el-input :placeholder="$t('code')" v-model="editSchoolyear.code" />
			</el-form-item>

			<el-form-item prop="range">
				<el-config-provider :locale="locale">
					<el-date-picker
						v-model="editSchoolyear.range"
						type="daterange"
						:unlink-panels="true"
						:range-separator="$t('to')"
						:start-placeholder="$t('startDate')"
						:end-placeholder="$t('endDate')" />
				</el-config-provider>
			</el-form-item>
		</el-form>

		<template #footer>
			<span class="dialog-footer">
				<el-button @click="cancelForm(editSchoolyearForm)">{{
					$t('cancel')
				}}</el-button>
				<el-button type="primary" @click="submitForm(editSchoolyearForm)">{{
					$t('confirm')
				}}</el-button>
			</span>
		</template>
	</el-dialog>

	<el-dialog :title="$t('importData')" v-model="importDataDialog" width="40%">
		<div class="text-center">
			<span>{{ $t('selectImportDataSchoolyear') }}</span>
			<div class="mb-10"></div>
			<el-select
				v-model="importDataSource"
				:placeholder="$t('select')"
				:no-data-text="$t('noData')"
				:no-match-text="$t('noMatchingData')"
				style="width: 300px">
				<el-option
					v-for="item in importSchoolyears"
					:key="item.id"
					:value="item.id"
					:label="item.code">
				</el-option>
			</el-select>
		</div>

		<template #footer>
			<span class="dialog-footer">
				<el-button @click="cancelImport()">{{ $t('cancel') }}</el-button>
				<el-button type="primary" @click="submitImport()">{{
					$t('confirm')
				}}</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
	import { mapState, mapGetters, mapActions } from 'vuex'

	import {
		reformatDate,
		formatDate,
		differenceWithToday,
	} from '@/utils/chronos'

	import { VarTypes } from '@/utils/enums'

	import fr from 'element-plus/lib/locale/lang/fr'

	import accessManager from '@/mixins/access-manager'

	export default {
		name: 'Schoolyears',
		mixins: [accessManager],
		data() {
			return {
				VarTypes,
				primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
				editSchoolyear: {
					code: '',
					range: '',
				},
				addSchoolyear: {
					code: '',
					range: '',
				},
				rules: {
					code: [
						{
							required: true,
							message: this.$t('fieldRequired'),
							trigger: 'blur',
						},
					],
					range: [
						{
							required: true,
							message: this.$t('fieldRequired'),
							trigger: 'blur',
						},
					],
				},
				editSchoolyearForm: 'editSchoolyearForm',
				addSchoolyearForm: 'addSchoolyearForm',
				importDataDialog: false,
				importDataTarget: null,
				importDataSource: null,
				currentEditItem: null,
				editDialog: false,
				isLoading: false,
				addDialog: false,
				pageSize: 6,
				locale: fr,
				search: '',
				total: 0,
				page: 1,
			}
		},
		computed: {
			...mapState({
				tableData: (state) => state.schoolyears.schoolyears,
			}),
			...mapGetters({
				getSettingValue: 'settings/getSettingValue',
			}),
			pagedTableData() {
				this.total = this.searching.length

				return this.searching.slice(
					this.pageSize * this.page - this.pageSize,
					this.pageSize * this.page
				)
			},
			searching() {
				if (!this.search) {
					this.total = this.tableData.length
					return this.tableData
				}

				this.page = 1

				return this.tableData.filter(
					(data) =>
						data.code.toLowerCase().includes(this.search.toLowerCase()) ||
						data.start_date.toLowerCase().includes(this.search.toLowerCase()) ||
						data.end_date.toLowerCase().includes(this.search.toLowerCase())
				)
			},
			importSchoolyears() {
				return this.tableData.filter(
					(schoolyear) => schoolyear.id != this.importDataTarget
				)
			},
		},
		methods: {
			...mapActions({
				fetchDataDB: 'data/fetchDataDB',
				loadSchoolyears: 'schoolyears/loadSchoolyears',
				fetchSchoolyearsDB: 'schoolyears/fetchSchoolyearsDB',
				getUserByCredentials: 'authentication/getUserByCredentials',
			}),
			reformatDate,
			differenceWithToday,
			setPage(val) {
				this.page = val
			},
			indexMethod(index) {
				return index + (this.page - 1) * this.pageSize + 1
			},
			async editItem(index, item) {
				this.currentEditItem = item

				this.editSchoolyear.code = item.code

				let start = item.start_date.split('-')
				let end = item.end_date.split('-')

				if (start.length === 3 && end.length === 3) {
					this.editSchoolyear.range = [
						new Date(start[0], parseInt(start[1]) - 1, start[2]),
						new Date(end[0], parseInt(end[1]) - 1, end[2]),
					]
				}

				this.editDialog = true
			},
			deleteItem(index, item) {
				this.$prompt(
					this.$t('deleteSchoolyearWarning'),
					this.$t('deleteSchoolyear'),
					{
						inputPlaceholder: this.$t('confirmWithPassword'),
						confirmButtonText: this.$t('confirm'),
						cancelButtonText: this.$t('cancel'),
						inputType: 'password',
					}
				)
					.then(async ({ value }) => {
						if (value) {
							let resp = await this.getUserByCredentials({
								username: this.userData.uid,
								password: value,
							})

							if (resp.success) {
								if (
									resp.data.username &&
									resp.data.password &&
									resp.data.user
								) {
									let task = await this.fetchSchoolyearsDB({
										action: 'deleteSchoolyear',
										params: {
											id: item.id,
										},
									})

									if (task.success) {
										this.$message.success(this.$t('success'))

										this.isLoading = true

										await this.loadSchoolyears()

										this.isLoading = false
									} else {
										this.$message.error(this.$t('anErrorOccured'))
									}
								} else {
									this.$message.error(this.$t('wrongPassword'))
								}
							} else {
								this.$message.error(this.$t('anErrorOccured'))
							}
						}
					})
					.catch(() => {})
			},
			submitForm(ref) {
				this.$refs[ref].validate(async (valid) => {
					if (valid) {
						if (ref === this.addSchoolyearForm) {
							let task = await this.fetchSchoolyearsDB({
								action: 'addSchoolyear',
								params: {
									code: this.addSchoolyear.code,
									start_date: formatDate(this.addSchoolyear.range[0], {
										outPattern: 'YYYY-MM-DD',
									}),
									end_date: formatDate(this.addSchoolyear.range[1], {
										outPattern: 'YYYY-MM-DD',
									}),
								},
							})

							if (task.success) this.$message.success(this.$t('success'))
							else this.$message.error(this.$t('anErrorOccured'))
						} else {
							let newData = {}

							let startDate = formatDate(this.editSchoolyear.range[0], {
									outPattern: 'YYYY-MM-DD',
								}),
								endDate = formatDate(this.editSchoolyear.range[1], {
									outPattern: 'YYYY-MM-DD',
								})

							if (this.currentEditItem.code !== this.editSchoolyear.code) {
								newData['code'] = this.editSchoolyear.code
							}

							if (this.currentEditItem.start_date !== startDate) {
								newData['start_date'] = startDate
							}

							if (this.currentEditItem.end_date !== endDate) {
								newData['end_date'] = endDate
							}

							if (!this.$_.isEmpty(newData)) {
								let task = await this.fetchSchoolyearsDB({
									action: 'editSchoolyear',
									params: {
										id: this.currentEditItem.id,
										data: newData,
									},
								})

								if (task.success) this.$message.success(this.$t('success'))
								else this.$message.error(this.$t('anErrorOccured'))
							}
						}

						this.cancelForm(ref)

						this.isLoading = true

						await this.loadSchoolyears()

						this.isLoading = false
					} else {
						return false
					}
				})
			},
			cancelForm(ref) {
				this.$refs[ref].resetFields()

				if (ref === this.addSchoolyearForm) this.addDialog = false
				else this.editDialog = false
			},
			async importData(index, item) {
				this.importDataDialog = true

				this.importDataTarget = item.id
			},
			async submitImport() {
				if (!this.importDataSource) {
					this.$message.warning(this.$t('selectSchoolyear'))
					return
				}

				if (!this.importDataTarget) {
					this.$message.error(this.$t('anErrorOccured'))
					return
				}

				let task = await this.fetchDataDB({
					action: 'transferData',
					params: {
						target: this.importDataTarget,
						source: this.importDataSource,
					},
				})

				console.log(task)
			},
			cancelImport() {
				this.importDataDialog = false

				this.importDataTarget = null

				this.importDataSource = null
			},
		},
	}
</script>
